<template>
  <div class="title">
    <span style="text-align:center">预警监控中心</span>

    <div style="float: right; width: 10%">
      <div style="display: flex;align-items: center;float: right;">
          <div class="secondspan" v-if="this.type == 0">
              <img src="../../../assets/img/fullscreen.png" @click="magnify()"/>
          </div>
      </div>
    </div>
  </div>
 
  <div class="page">
    <div style="padding: 10px;display: flex;justify-content: space-between;">
      <!-- 预警总数 -->
      <div class="box">
        <div class="t_pybox"><span class="t">预警总数</span><span class="py"></span></div>
        <div id="totalNum" style='width:100%;height: 280px;'></div>
        
        <div class="flexnum">
          <div class="num">{{ this.warningData.total }}</div>
          <div class="num">{{ this.warningData.processedTotal }}</div>
        </div>
        <div class="flexnum" style="margin-bottom:20px;">
          <div class="c">预警总数</div>
          <div class="c">已处理次数</div>
        </div>
        <div class="flexnum">
          <div class="num">{{ this.warningData.ignoreTotal }}</div>
          <div class="num">{{ this.warningData.notProcessedTotal }}</div>
        </div>
        <div class="flexnum" style="margin-bottom:20px;">
          <div class="c">忽略次数</div>
          <div class="c">未处理次数</div>
        </div>
      </div>
      <!-- 地图 -->
      <div class="map">
        <div id="map" class='amap-wrapper'></div>
      </div>
      
      <!-- 当日数据 -->
      <div class="box">
        <div class="t_pybox"><span class="t">当日数据</span><span class="py"></span></div>
        <div id="todayData" style="width:100%;height: 280px"></div>
        <div class="flexnum">
          <div class="num">{{ this.warningData.today }}</div>
          <div class="num">{{ this.warningData.processedToday }}</div>
        </div>
        <div class="flexnum" style="margin-bottom:20px;">
          <div class="c">当日预警</div>
          <div class="c">已处理次数</div>
        </div>
        <div class="flexnum">
          <div class="num">{{ this.warningData.ignoreToday }}</div>
          <div class="num">{{ this.warningData.notProcessedToday }}</div>
        </div>
        <div class="flexnum" style="margin-bottom:20px;">
          <div class="c">忽略次数</div>
          <div class="c">未处理次数</div>
        </div>
      </div>
    </div>

    <div style="padding: 0px 10px;display: flex;justify-content: space-between;">
      <!-- 预警总数报表 -->
      <div class="box2">
        <div class="t_pybox"><span class="t">预警总数报表</span><span class="py"></span></div>
        <div id="totalLine" style='width:100%;height: 300px;'></div>
      </div>
      <!-- 历史报警记录 -->
      <div class="box3" >
        <div class="t_pybox"><span class="t">历史报警记录({{dataList.length}})</span><span class="py"></span></div>

        <el-table :data="dataList" @row-click="rowClick" height="300" :show-header="false"  :empty-text="emptyText">
          <el-table-column type="index" label="序号" width="55" label-class-name="tablehead" align="center" />
          <el-table-column prop="createTime" label="时间" label-class-name="tablehead" align="center" >
            <template #default="scope">
              <span>{{scope.row.createTime.substring(0,16)}}</span>
            </template>
          </el-table-column>

          <el-table-column prop="warningTypeDescribe" label="预警类型" label-class-name="tablehead" align="center" />

          <el-table-column prop="dealState" label="状态" width="70" label-class-name="tablehead" align="center" >
            <template #default="scope">
              <span v-if="scope.row.dealState == 1" style="color:red">未处理</span>
              <span v-if="scope.row.dealState == 2" style="color:green">已处理</span>
              <span v-if="scope.row.dealState == 3" style="color:#FFB640">已忽略</span>
            </template>
          </el-table-column>
          
        </el-table>

      </div>
    </div>
  </div>

  <template v-if="dealPage">
    <DeviceWarningDeal ref="dealView" @update="update" @cancel="cancelDeal"></DeviceWarningDeal>
  </template>
</template>

<script src='./js/warn-monitor.js'></script>

<style scoped src="./css/warn-monitor.css"></style>

<style>
  .amap-info-content {
    overflow: hidden;
    padding: 0px;
  }
  .box3 .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0px;
  }
  .box3 .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 2px;
    height: 50px;
    background: #eee;
  }
  .box3 .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.4);
  }
  

</style>