import * as echarts from 'echarts'
import warnTotalJs from "./warn-total";
import warnTodayJs from "./warn-today";
import warnTotalReportJs from "./warn-total-report";
import {
  getDeviceWarningLngLatInfos,
  getWarningMultiCount,
  getWarningInfosWithDay
} from '../../../../api/api'
import mapInfo from './map-info'
import session from "../../../../store/store";
import until from '../../../../until/until'

import DeviceWarningDeal from '../../device-warning/DeviceWarningDeal.vue'

export default {
  components: {
    DeviceWarningDeal
  },
   data() {
       return {
        type: 0,//0非全屏  1：全屏
        emptyText:'加载中',
        map:null,
        infoWindow:null,
        iconBlue:null,
        dataList: [],
        role:session.getLoginInfo().accountType,
        dealPage:false,
        deviceData:null,
        warningData:{},
        monthPicker: '',//月选择器绑定值
        yearPicker: "", //年选择器绑定值
        timeId:null,
        lnglatUuid:null,
       };
   },
   mounted() {
     this.init();
     if(this.$route.query.type != null) {
      this.type = this.$route.query.type;

      document.title = session.getSysName() + '-' + '预警监控中心'
    }
   },
   unmounted() {
    if(this.map != null) {
      this.map.destroy()
      this.map = null
    }

    if(null != this.timeId) {
      clearTimeout(this.timeId)
      this.timeId = null
    }
  },
   methods: {
     init() {
      this.getNowDateMonth()
      this.getWarningMultiCount()
      this.getWarningInfosWithDay()

       this.initMap();

     },

     getWarningMultiCount() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
      }
      getWarningMultiCount(param).then(res => {
        if (res.data.code == 200) {
          this.warningData = res.data.dataInfo;

          // ignoreToday
          // ignoreTotal
          // notProcessedToday
          // notProcessedTotal
          // processedToday
          // processedTotal
          // today
          // total
          var totalList = []
          // totalList.push({'value':this.warningData.total,'name':'预警次数'})
          totalList.push({'value':this.warningData.processedTotal,'name':'已处理次数'})
          totalList.push({'value':this.warningData.ignoreTotal,'name':'忽略次数'})
          totalList.push({'value':this.warningData.notProcessedTotal,'name':'未处理次数'})
          warnTotalJs.setWarnTotalChart(echarts,totalList);

          var todayList = []
          // todayList.push({'value':this.warningData.today,'name':'当日预警'})
          todayList.push({'value':this.warningData.processedToday,'name':'已处理次数'})
          todayList.push({'value':this.warningData.ignoreToday,'name':'忽略次数'})
          todayList.push({'value':this.warningData.notProcessedToday,'name':'未处理次数'})
          warnTodayJs.setWarnTodayChart(echarts,todayList);

        }
      })
    },

        /**
      * 默认当前月
      */
        getNowDateMonth() {
          var now = new Date();
          var year = now.getFullYear(); //得到年份
          var month = now.getMonth(); //得到月份
          month = month + 1;
          month = month.toString().padStart(2, "0");
          var defaultMonthDate = year + "-" + month;
          this.monthPicker = defaultMonthDate;
          this.yearPicker = year.toString();
        },

        getWarningInfosWithDay(){
          let param = {
            unitUuid:session.getLoginInfo().unitUuid,
            role:this.role,
            dateTime:this.monthPicker
          }
          getWarningInfosWithDay(param).then(res => {
            if (res.data.code == 200) {
              var list = res.data.dataInfo;
              var dataList = []
              list.forEach(data => {
                dataList.push(data.number)
              });
              warnTotalReportJs.setWarnTotalReportChart(echarts,dataList,1);
            }
         
          })
        },
        /**
     * 地图初始化
     */
    initMap() {
      this.map = new AMap.Map('map', {
        resizeEnable: true,
        zoom: 17,
        // viewMode:'3D', //开启3D视图,默认为关闭
      });
      
      this.infoWindow = new AMap.InfoWindow({
          // isCustom: true,  //使用自定义窗体
        offset: new AMap.Pixel(7, 0),
      });

      this.iconBlue = new AMap.Icon({
        image : require('../../../../assets/img/point-blue.png'),
        size : new AMap.Size(14,23)
      });


  

      window.ignore = (res) => {
        // 使用_this可以继续调用vue中的属性和方法，也可以完成绑定事件
        console.log("ignore",res);
        this.dealPage = true
        setTimeout(() => {
          this.$refs.dealView.getParentData(this.deviceData,0);
        }, 200);
      },
      window.dealWith = (res) => {
        // 使用_this可以继续调用vue中的属性和方法，也可以完成绑定事件
        console.log("dealWith",res);
        this.dealPage = true
        setTimeout(() => {
          this.$refs.dealView.getParentData(this.deviceData,1);
        }, 200);
      }
      
      this.initData()


    },

    initData() {
      this.getDeviceWarningLngLatInfos()

      this.timeId = setInterval(() => {
        this.getDeviceWarningLngLatInfos()
      }, 5000);
    },

    update() {
      this.getDeviceWarningLngLatInfos();
    },

    getDeviceWarningLngLatInfos() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
        page:0,
        size:10,
      }
      getDeviceWarningLngLatInfos(param).then(res => {
        if (res.data.code == 200) {
          this.dataList = res.data.dataInfo;
          this.deviceData = res.data.dataInfo[0]
          if(this.lnglatUuid != this.deviceData.uuid) {
            this.lnglatUuid = this.deviceData.uuid
            this.addMarker(this.deviceData)
          }
          
        }else {
          this.dataList.splice(0, this.dataList.length);
          this.emptyText = "暂无数据"
        }
      })
    },
    rowClick(row) {
      this.deviceData = row
      this.addMarker(row)
    },
    addMarker(data) {
      this.map.clearMap()
      if((data.lat == '0') && (data.lng == '0')) {
        this.$message.error({ message: "无定位信息" });
        return 
      }
      var marker = new AMap.Marker({
        position: new AMap.LngLat(data.lng, data.lat),//位置
        icon: this.iconBlue, // 添加 Icon 实例
      })
      data.address = data.address.replaceAll("#","")

      if (until.isNULL(data.userName)) {
        data.userName = '未绑定'
      }

      this.infoWindow.setContent(mapInfo.getHtml(data));

      marker.on('click', () => {
        if(!this.infoWindow.getIsOpen()) {
            this.infoWindow.open(this.map, marker.getPosition());
        }else {
          this.infoWindow.close();
        }
      });
      this.infoWindow.open(this.map, marker.getPosition());
      this.map.add(marker);
      this.map.setFitView(marker,true);

      setTimeout(() => {
        this.map.panBy(0,80);
      }, 500);
    },

    cancelDeal() {
      this.dealPage = false;
    },
    magnify() {
      const routeData = this.$router.resolve({
          path: "/warn-monitor-full-screen",
          query: {
              type: 1     //全屏
           } 
        })
      window.open(routeData.href)
    }
  }

     
};