/**
 * 当日数据
 */

export default {
    setWarnTotalOption(echarts,option) {
        const doc = document.getElementById("totalNum")
        let myChart = echarts.getInstanceByDom(doc);
        if (myChart == undefined) {
            myChart = echarts.init(doc); // 基于准备好的dom，初始化echarts实例
        }
        myChart.clear(); // 清除画布
        doc.setAttribute('_echarts_instance_', '')
        myChart.setOption(option); // 使用刚指定的配置项和数据显示图表。
    },

    setWarnTotalChart(echarts,data) {
        var option = {
            color: ['#35EFE0', '#0075FD', '#9383F6', '#FF9452', '#FA7F97'],//配置颜色
            tooltip: {
                trigger: 'item',
                formatter: "{a} <br/>{b}: {c} ({d}%)",
                position: [10, 1],
            },
            series: [
                {
                name: '预警记录',
                type: 'pie',
                radius: ['10%', '80%'],
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    formatter: '{d}%',//设置左边图上的%
                    position: 'inner',
                    fontSize: 14,
                    color:'#FFFFFF'
                },
                labelLine: {
                    normal: {
                        show: false
                    }
                },
                data: data
                }
            ]
        };

        this.setWarnTotalOption(echarts,option);
    },
};