/**
 * 预警总数报表
 */
 export default {
     setWarnTotalReportOption(echarts,option) {
         const doc = document.getElementById("totalLine")
         let myChart = echarts.getInstanceByDom(doc);
         if (myChart == undefined) {
             myChart = echarts.init(doc); // 基于准备好的dom，初始化echarts实例
         }
         myChart.clear(); // 清除画布
         doc.setAttribute('_echarts_instance_', '')
         myChart.setOption(option); // 使用刚指定的配置项和数据显示图表。
         window.onresize = myChart.resize
     },
 
     setWarnTotalReportChart(echarts,dataList,dateType) {
      var xdata = [];
      if(1 == dateType) {
        if (dataList.length == 30) {
          xdata = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17',
            '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'
          ]
        } else if (dataList.length == 31) {
          xdata = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17',
            '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
          ]
        } else if (dataList.length == 29) {
          xdata = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17',
            '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29'
          ]
        } else if (dataList.length == 28) {
          xdata = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17',
            '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28'
          ]
        }
      }else if(2 == dateType){
        xdata = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
      }else if(3 == dateType){
        xdata = ['一季度', '二季度', '三季度', '四季度'];
      }
        var option = {
            tooltip: {
                trigger: 'axis'
              },
              color: ['#93adf7'],
              grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
              },
              xAxis: [
                {
                  type: 'category',
                  boundaryGap: false,
                  data: xdata
                }
              ],
              yAxis: [
                {
                  type: 'value'
                }
              ],
              series: [
                {
                  name: 'Line 1',
                  type: 'line',
                  stack: 'Total',
                  smooth: true,
                  lineStyle: {
                    width: 2,
                    color: '#9383F6'
                  },
                  showSymbol: true,
                  areaStyle: {
                    opacity: 0.8,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: '#0CB6FF'
                      },
                      {
                        offset: 1,
                        color: '#6ADCF5'
                      }
                    ])
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: dataList
                }
              ]
        };

        this.setWarnTotalReportOption(echarts,option);
    },
 };