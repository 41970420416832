/* eslint-disable */

export default {

  getHtml(data) {
    var c1 = ` <div style="width:380px;">
    <div style="background-color: red;height: 30px;padding: 5px 0px;border-radius: 8px 8px 0px 0px;">
        <div style="margin-left: 10px;height: 100%;display: flex;">
          <span style="font-size: 17px;color: white;align-self: center;font-weight: bold;">设备预警</span>
        </div>
    </div>

    <div style="background-color: white;height: 150px;">

      <div style="margin-left: 10px;height: 25%;display: flex;">
        <div style="width:50%;display: flex;justify-content: flex-start;align-items: center;">
          <span style="font-size: 13px;align-self: center;">预警时间：</span>
          <span style="font-size: 13px;color: black;align-self: center;margin-top: 3px;">${data.createTime.substring(0,16)}</span>
        </div>
        <div style="width:50%;display: flex;justify-content: flex-start;align-items: center;">
          <span style="font-size: 13px;align-self: center;">设备编号：</span>
          <span style="font-size: 13px;color: black;align-self: center;margin-top: 3px;">${data.deviceId}</span>
        </div>
      </div>
      <div style="margin-left: 10px;height: 25%;display: flex;">
        <div style="width:50%;display: flex;justify-content: flex-start;align-items: center;">
          <span style="font-size: 13px;align-self: center;">设备名称：</span>
          <span style="font-size: 13px;color: black;align-self: center;margin-top: 3px;">${data.deviceName}</span>
        </div>
        <div style="width:50%;display: flex;justify-content: flex-start;align-items: center;">
          <span style="font-size: 13px;align-self: center;">设备型号：</span>
          <span style="font-size: 13px;color: black;align-self: center;margin-top: 3px;">${data.deviceModel}</span>
        </div>
      </div>
      <div style="margin-left: 10px;height: 25%;display: flex;">
        <div style="width:50%;display: flex;justify-content: flex-start;align-items: center;">
          <span style="font-size: 13px;align-self: center;">预警类型：</span>
          <span style="font-size: 13px;color: black;align-self: center;margin-top: 3px;">${data.warningTypeDescribe}</span>
        </div>
      
        <div style="width:50%;display: flex;justify-content: flex-start;align-items: center;">
          <span style="font-size: 13px;align-self: center;">绑定用户：</span>
          <span style="font-size: 13px;color: black;align-self: center;margin-top: 3px;">${data.userName}</span>
        </div>
      </div>
      
      <div style="margin-left: 10px;height: 30%;display: flex;">
        <div style="width:100%;display: flex;justify-content: flex-start;align-items: center;margin-right:5px;">
          <span style="font-size: 13px;align-self: center;">预警位置：</span>
          <span style="font-size: 13px;color: black;align-self: center;margin-top: 3px;">${data.address}</span>
        </div>
      </div>

    </div>
    `
    var c2 = null
    if(data.dealState == 1) {
      c2 = `<div style="height: 25%;display: flex;justify-content: center;align-items: center;">
            <div style="height:30px;width:60px;background-color: #E6A23C;color: white;line-height: 30px;text-align: center;border-radius:10px" onclick="ignore('${data.deviceId}')">忽略</div>
            <div style="margin-left: 10px;height:30px;width:60px;background-color: #285DF0;color: white;line-height: 30px;text-align: center;border-radius:10px" onclick="dealWith('${data.deviceId}')">处理</div>
          </div>

        </div>
        <div style="width: 0; height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-top: 10px solid #FFF;
                    position: relative;
                    left: 50%;
                    margin-left: -10px;">
        </div>`
    }else if(data.dealState == 2) {
      c2 = `<div style="height: 25%;display: flex;justify-content: center;align-items: center;">
          <div style="height:30px;width:60px;background-color: #285DF0;color: white;line-height: 30px;text-align: center;border-radius:10px" onclick="dealWith('${data.deviceId}')">处理</div>
        </div>

      </div>
      <div style="width: 0; height: 0;
                  border-left: 10px solid transparent;
                  border-right: 10px solid transparent;
                  border-top: 10px solid #FFF;
                  position: relative;
                  left: 50%;
                  margin-left: -10px;">
      </div>`
    }else if(data.dealState == 3) {
      c2 = `
        <div style="height: 25%;display: flex;justify-content: center;align-items: center;">
        </div>

      </div>
      <div style="width: 0; height: 0;
                  border-left: 10px solid transparent;
                  border-right: 10px solid transparent;
                  border-top: 10px solid #FFF;
                  position: relative;
                  left: 50%;
                  margin-left: -10px;">
      </div>`
    }
   return c1 + c2
  }

}

